<template>
  <!-- 实名认证 -->
  <div class="my-certification normal">
    <div class="top">
      <div class="s-title">
        <h3 class="title">实名认证</h3>
      </div>
      <div class="top-con">
        <p class="desc">您可以通过以下方式完成实名认证/备案</p>
        <ul>
          <li class="box" @mouseenter="isCard = 1" @click="routerGo(1)" v-show="['4'].indexOf(identityObj.identityTypeCode)>-1">
            <!-- 集体经济组织 新型经营主体 -->
            <p style="margin: 0">
              <img
                src="imgs/nonghu.png"
                style="margin: 15px 0 4px 0; width: 58px"
                alt=""
              />
            </p>
            <p class="number" style="margin-top: 10px">追加认证为农户</p>
          </li>
          <li class="box" @mouseenter="isCard = 1" @click="routerGo(1)" v-show="['3'].indexOf(identityObj.identityTypeCode)>-1 && identityList.indexOf('2')==-1">
            <!-- 集体经济组织 新型经营主体 -->
            <p style="margin: 0">
              <img
                src="imgs/nonghu.png"
                style="margin: 15px 0 4px 0; width: 58px"
                alt=""
              />
            </p>
            <p class="number" style="margin-top: 10px">追加认证为农户</p>
          </li>
          <li class="box" @mouseenter="isCard = 2" @click="routerGo(3)" v-show="['2','3','4'].indexOf(identityObj.identityTypeCode)>-1">
            <!-- 农户 集体经济组织 新型经营主体-->
            <p style="margin: 0"><img src="imgs/icon-idCard.png" alt="" /></p>
            <p class="number">追加认证为新型经营主体</p>
            <p class="number" style="margin-top: 10px; font-size: 14px">
              (家庭农场、合作社、农业企业等)
            </p>
          </li>
          <li class="box" @mouseenter="isCard = 3" @click="routerGo(2)" v-show="['2','4'].indexOf(identityObj.identityTypeCode)>-1">
            <!-- 农户 新型经营主体-->
            <p style="margin: 0">
              <img src="imgs/icon-idCard.png" style="margin: 15px 0" alt="" />
            </p>
            <p class="number" style="margin-top: 10px">
              追加认证为集体经济组织
            </p>
          </li>
        </ul>
        <div></div>
      </div>
    </div>
    <!-- <div class="remark" v-if="isCard == 1">
      <p>方式说明：</p>
      <p>线下实名认证指会员个人需到就近的产权交易服务中心现场进行身份认证。</p>
      <p>
        现场认证需准备以下材料：<span style="color: #00a0e9"
          >个人有效身份证原件、个人有效的银行卡、银行卡开户预留手机号。</span
        >
      </p>
      <p>
        企业用户需准备以下材料：<span style="color: #00a0e9"
          >有效期内企业营业执照、企业税务登记证、企业银行账户。</span
        >
      </p>
    </div> -->
    <div class="remark" v-if="isCard == 2">
      <p>方式说明：</p>
      <p style="text-indent: 24px;">
        企业用户需准备以下材料：<span  style="color: red">有效期内企业营业执照、企业税务登记证、企业银行账户。</span>
      </p>
    </div>
    <!-- <div class="remark" v-if="isCard == 3">
      <p>方式说明：</p>
      <p style="color: #00a0e9">
        根据功能界面需求，如实填写您的企业及账户信息，完成企业用户认证。
      </p>
    </div> -->
  </div>
</template>

<script>
import { getCertificateStatus } from "@/api/account";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      role: 3,
      isCard: 1,
      status: 0,
      msg: "",
      identityList:[]
    };
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  created() {
    let identityList=[];
    if(this.userInfo.identityList.length>1){
      this.userInfo.identityList.forEach(item=>{
        identityList.push(item.identityTypeCode);
      })
    }
    this.identityList=identityList;
    console.log(this.identityList)
  },
  methods: {
    routerGo(val) {
      if (val == 1) {
        this.$router.push({ name: "register1",query:{isAdd:true} });
      } else if (val == 2) {
        this.$router.push({ name: "register2",query:{isAdd:true}  });
      } else {
        this.$router.push({ name: "register3",query:{isAdd:true}  });
      }
    },
    async getStatus() {
      // 判断认证状态
      let res = await getCertificateStatus(this.userInfo.username);
      let { code, data, msg } = res;
      if (code == 0) {
        this.status = data;
        this.msg = msg;
      }
      // this.status = this.userInfo.authSta;
      // if(this.status == 5) {
      //   // 审核
      //   this.$message.info(this.msg)
      // } else if(this.status == 1) {
      //   // 成功
      //   this.$message.info(this.msg)
      // } else if(this.status == 2) {
      //   // 失败
      //   this.$message.info(this.msg)
      //   this.$router.push({name: 'certificateByCard'})
      // } else {
      //   this.$router.push({name: 'certificateByCard'})
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.my-certification {
  text-align: left;
  margin-top: 0 !important; 
  ul {
    display: flex;
    padding: 0;
    .box {
      width: 260px;
      height: 170px;
      padding: 20px 20px 20px 30px;
      display: block;
      box-sizing: border-box;
      background-image: linear-gradient(
          -70deg,
          #2982fe 0%,
          #04d4e7 100%,
          #7cb1bc 100%,
          #f38d90 100%
        ),
        linear-gradient(90deg, #fe9c5f 0%, #f8323f 100%);
      background-blend-mode: normal, normal;
      border-radius: 15px;
      color: #fff;
      text-align: center;
      cursor: pointer;
      margin-left: 30px;
      .type {
        text-align: center;
        margin: 0;
      }
      .number {
        margin-top: 20px;
        margin-bottom: 0;
        text-align: center;
        font-size: 18px;
      }
    }
    .box:last-child {
      margin-left: 30px;
    }
  }
  .top {
    .title {
      margin-left: 10px;
      font-size: 18px;
      font-weight: 600;
    }
    .top-con {
      padding-left: 21px;
      .desc {
        font-size: 20px;
        margin-bottom: 40px;
        margin-top: 12px;
      }
    }
  }
  .remark {
    margin-top: 50px;
    padding-left: 22px;
    p {
      margin: 0;
      padding: 0;
      color: #333333;
      font-size: 14px;
    }
    p:first-child {
      font-size: 16px;
      margin-bottom: 15px;
    }
    p:last-child {
      margin-top: 8px;
      span {
        color: #00a0e9;
      }
    }
  }
}
</style>
